<template>
  <component :is="clientData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === undefined"
    >
      <h4 class="alert-heading">
        {{ $t('Error fetching client data') }}
      </h4>
      <div class="alert-body">
        {{ $t('No client found with this user id. Check') }}
        <b-link
          class="alert-link"
          :to="{ name: 'clients-list'}"
        >
          {{ $t('Client List') }}
        </b-link>
        {{ $t('for other clients.') }}
      </div>
    </b-alert>

    <template v-if="clientData">
      <b-row>
        <b-col
          cols="12"
        >
          <client-edit-information
            :client-data="clientData"
            class="mt-2 pt-75"
          />
        </b-col>
      </b-row>
    </template>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BRow, BCol,
} from 'bootstrap-vue'

import { ref, onUnmounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import ClientEditInformation from '@/views/pages/client/clients-edit/ClientEditInformation.vue'
import clientsStoreModule from '../clientsStoreModule'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BRow,
    BCol,
    ClientEditInformation,
  },
  setup() {
    const clientData = ref(null)

    const CLIENT_STORE_MODULE_NAME = 'clients'

    // Register module
    if (!store.hasModule(CLIENT_STORE_MODULE_NAME)) store.registerModule(CLIENT_STORE_MODULE_NAME, clientsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CLIENT_STORE_MODULE_NAME)) store.unregisterModule(CLIENT_STORE_MODULE_NAME)
    })

    store.dispatch('clients/fetchClient', { id: router.currentRoute.params.id })
      .then(response => {
        clientData.value = response.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          clientData.value = undefined
        }
      })

    return {
      clientData,
    }
  },
}
</script>

<style>

</style>
