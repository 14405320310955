<template>
  <div>
    <b-media class="mb-3">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="localOptions.avatar"
          :text="avatarText(localOptions.name)"
          size="50px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ localOptions.name }}
      </h4>
    </b-media>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        {{ $t('Company Information') }}
      </h4>
    </div>

    <!-- Form: Personal Info Form -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>

          <!-- Field: Name -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              rules="required"
            >
              <b-form-group
                :label="$t('Name')"
                label-for="name"
              >
                <b-form-input
                  id="name"
                  v-model="localOptions.name"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Registration number -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Registration number')"
              label-for="registrationNumber"
            >
              <b-form-input
                id="registrationNumber"
                v-model="localOptions.registration_number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: VAT -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('VAT')"
              label-for="vat"
            >
              <b-form-input
                id="vat"
                v-model="localOptions.vat"
              />
            </b-form-group>
          </b-col>

          <!-- Tax payer -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Tax payer')"
              label-for="is-taxpayer"
            >
              <b-form-checkbox
                id="is-taxpayer"
                v-model="localOptions.is_taxpayer"
                switch
                inline
                value="1"
                unchecked-value="0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- Field: Bank account -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Bank account')"
              label-for="bankAccount"
            >
              <b-form-input
                id="bankAccount"
                v-model="localOptions.bank_account_number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Address -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Address
          </h4>
        </div>

        <b-row class="mt-1">

          <!-- Field: Address Line 1 -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Address')"
              label-for="address"
            >
              <b-form-input
                id="address"
                v-model="localOptions.address"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Postcode -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Postcode')"
              label-for="postcode"
            >
              <b-form-input
                id="postcode"
                v-model="localOptions.post_code"
                type="number"
                placeholder=""
              />
            </b-form-group>
          </b-col>

          <!-- Field: City -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('City')"
              label-for="city"
            >
              <b-form-input
                id="city"
                v-model="localOptions.city"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Country -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <!-- Country -->
            <validation-provider
              #default="validationContext"
              name="Country"
            >
              <b-form-group
                :label="$t('Country')"
                label-for="country"
                :state="getValidationState(validationContext)"
              >
                <v-select
                  v-model="localOptions.country"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countries"
                  :clearable="false"
                  input-id="country"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <!-- Header: Contact -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MailIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            {{ $t('Contact details') }}
          </h4>
        </div>

        <b-row class="mt-1">
          <!-- Field: Contact person -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Contact person')"
              label-for="contactperson"
            >
              <b-form-input
                id="contact-person"
                v-model="localOptions.contact_person"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <validation-provider
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                :label="$t('Email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="localOptions.email"
                  type="email"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Phone -->
          <b-col
            cols="12"
            md="6"
            lg="4"
          >
            <b-form-group
              :label="$t('Phone')"
              label-for="phone"
            >
              <b-form-input
                id="phone"
                v-model="localOptions.phone"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="2"
            lg="2"
          >
            <!-- Verified -->
            <b-form-group
              :label="$t('Verified')"
              label-for="is-verified"
            >
              <b-form-checkbox
                id="is-verified"
                v-model="localOptions.is_verified"
                value="1"
                unchecked-value="0"
                switch
                inline
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
            lg="2"
          >
            <!-- Active -->
            <b-form-group
              :label="$t('Active')"
              label-for="is-active"
            >
              <b-form-checkbox
                id="is-active"
                v-model="localOptions.is_active"
                switch
                inline
                value="1"
                unchecked-value="0"
              />

            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
            lg="2"
          >
            <!-- Test account -->
            <b-form-group
              :label="$t('Test account')"
              label-for="is-test"
            >
              <b-form-checkbox
                v-model="localOptions.is_test"
                switch
                inline
                value="1"
                unchecked-value="0"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="4"
            lg="4"
          >
            <!-- Subscription expires -->
            <b-form-group
              :label="$t('Subscription expires')"
              label-for="subscription-expires-at"
            >
              <b-form-datepicker
                id="subscription-expires-at"
                v-model="localOptions.subscription_expires_at"
                class="mb-1"
                reset-button
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Notes -->
        <b-row>
          <b-col>
            <b-form-group
              :label="$t('Notes')"
              label-for="notes"
            >
              <b-form-textarea
                id="notes"
                v-model="localOptions.notes"
                placeholder="Notes"
                rows="5"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <!-- Send verify client data email -->
            <b-form-group
              :label="$t('Send verify client email')"
              label-for="verify-client-email"
            >
              <b-form-checkbox
                v-model="localOptions.verify_client_email"
                switch
                inline
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-alert
              v-model="showSuccess"
              v-height-fade.appear
              variant="success"
              dismissible
              class="mb-0"
            >
              <div class="alert-body">
                {{ $t('Client added') }}
              </div>
            </b-alert>

            <b-alert
              v-model="showError"
              v-height-fade.appear
              variant="danger"
              dismissible
              class="mb-0"
            >
              <div class="alert-body">
                {{ errors }}
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              type="submit"
            >
              {{ $t('Save Changes') }}
            </b-button>
            <b-button
              variant="outline-secondary"
              type="button"
              @click="hide"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BMedia,
  BAvatar,
  BFormCheckbox,
  BFormDatepicker,
  BFormTextarea,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import formValidation from '@core/comp-functions/forms/form-validation'
import { alphaNum, email, required } from '@core/utils/validations/validations'

export default {
  components: {
    BAvatar,
    BMedia,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormDatepicker,
    BFormTextarea,

    vSelect,
    ValidationProvider,
    ValidationObserver,
    BFormInvalidFeedback,
  },
  props: {
    clientData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      localOptions: JSON.parse(JSON.stringify({
        ...this.clientData,
        verify_client_email: false,
      })),
      verify_client_email: false,
      countries,
      showError: false,
      errors: null,
      showSuccess: false,
    }
  },
  setup() {
    const resetClientData = () => {
      this.localOptions = JSON.parse(JSON.stringify({
        ...this.clientData,
        verify_client_email: false,
      }))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetClientData)

    return {
      required,
      alphaNum,
      email,
      refFormObserver,
      avatarText,
      resetForm,
      getValidationState,
    }
  },
  methods: {
    onSubmit() {
      this.showError = false
      this.showSuccess = false
      this.errors = null

      store.dispatch('clients/updateClient', this.localOptions)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Client updated'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          console.log(error)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('Error updating client'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })

          this.showError = true
          this.errors = error.response.data.message
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
